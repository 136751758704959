import {
    Form,
    Row,
    Input,
    Space,
    Button,
    message,
    Modal,
    Select,
  } from "antd";
  import { useEffect, useState } from "react";
  import { Link, useNavigate } from "react-router-dom";
  import { ExclamationCircleOutlined } from "@ant-design/icons";
  import CustomUpload from "../customUpload";
import axiosInstance from "../../utils/axios";
import CustomSunEditor from "../customSunEditor";
  
  export default function BlogForm({
    id = "",
    initialValues = {},
    onSubmit = () => {},
  }) {
    const navigate = useNavigate();
    const [editorValue, setEditorValue] = useState('');
    const [form] = Form.useForm();
    const [categories, setCategories] = useState([]);
    const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 100,
    });
  
    const fetchCategories = async () => {
      await axiosInstance.get(`/category-blog/getAll`, {params: pagination})
        .then((res) => {
          const data = res?.data;
          setCategories(data);
        })
        .catch(() => message.error("Error server!"));
    };
  
    const deleteBlog = async () => {
      await axiosInstance.delete(`/blog/delete/${id}`)
        .then((res) => message.success(String(res?.data?.message)))
    };
  
    useEffect(() => {
      fetchCategories();
      if (Object.keys(initialValues)?.length > 0) {
        form.resetFields();
      }
    }, [form, initialValues]);
  
    const removeBlog = async () => {
      try {
        await deleteBlog();
        return navigate("/admin/quan-ly-blog");
      } catch (err) {
        console.log(err.message);
      }
    };
  
    const confirmDeleteBusiness = () => {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        content: "Bạn có chắc chắn xoá blog này?",
        okText: "Xác nhận",
        cancelText: "Huỷ",
        onOk: () => removeBlog(),
      });
    };
    return (
      <div className={"p-[40px] bg-white rounded-[10px]"}>
        <div className={"!text-[#2d2e32] pb-[10px]"}>
          <Link
            to={"/admin/quan-ly-blog"}
            className={
              "text-[18px] sm:text-[24px] md:text-[26px] xl:text-[26px] font-[500] cursor-pointer "
            }
          >
            {"Thông tin blog"}
          </Link>
        </div>
  
        <Form
          layout={"vertical"}
          colon={false}
          form={form}
          initialValues={initialValues}
          onFinishFailed={(e) => console.log(e)}
          onFinish={onSubmit}
        >
            <Form.Item
                label={"Tên"}
                name="blog_name"
                rules={[{ required: true, message: "Vui lòng nhập tên!" }]}
            >
                <Input size="large" placeholder={"Nhập"} />
            </Form.Item>
    
            <Form.Item
                name="blog_image"
                label={"Link ảnh"}
                rules={[{ required: true, message: "Vui lòng chọn file!" }]}
            >
                <CustomUpload type="image" accept=".png, .jpg, .jpeg, .jfif" />
            </Form.Item>

            <Form.Item label={"Danh mục Blog"} name="category_blog_id">
              <Select
                  showSearch
                  size="large"
                  placeholder="Select a person"
                  optionFilterProp="children"
                  options={categories?.data?.map((value) => ({
                    value: value.category_blog_id,
                    label: value.category_blog_name,
                  }))}
              />
            </Form.Item>

            <Form.Item
                label={"Nội dung"}
                name="blog_description"
                rules={[{ required: true, message: "Vui lòng nhập nội dung!" }]}
            >
                <CustomSunEditor value={editorValue} onChange={(e)=> setEditorValue(e)} />
            </Form.Item>
  
          <Row gutter={40} className={"my-[40px] pl-[20px]"}>
              <Space align="center">
              <Button type={"primary"} htmlType={"submit"}>
                  {id && id !== "tao-moi" ? "Cập nhập" : "Tạo"}
              </Button>
              {id && id !== "tao-moi" && (
                  <Button type={"primary"} danger onClick={confirmDeleteBusiness}>
                      Xoá
                  </Button>
              )}
              </Space>
          </Row>
        </Form>
      </div>
    );
  }
  