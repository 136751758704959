import { useNavigate } from "react-router-dom";
import { useDevice } from "../../hooks";
import { Breadcrumb, Col, Empty, Pagination, Row } from "antd";
import { useEffect, useState } from "react";
import { getAllBlog, getAll } from "../../api/blog";
import parse from "html-react-parser";
import { Slide } from "react-slideshow-image";
import { properties } from '../../utils/constants';
import dayjs from "dayjs";

export default function Blog() {
    const { isMobile } = useDevice();
    const navigate = useNavigate();
    const [blog, setBlog] = useState();
    const [category, setCategory] = useState();
    const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 12,
    });

    useEffect(()=> {        
        const query = `page=${pagination?.page}&pageSize=${pagination?.pageSize}`;
        getAllBlog(query, setBlog);
        getAll(setCategory);
    }, [pagination])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 

    return (
        <div className={`max-w-screen-2xl mx-auto my-5 ${isMobile && "pt-14"}`}>
            <div>
                <Breadcrumb
                    items={[
                        {
                            title: <a href="/">Trang chủ</a>,
                        },
                        {
                            title: <a href="/blog">Blog</a>,
                        }
                    ]}
                />
            </div>
            {isMobile ? (
                <div className='bg-white rounded-lg mt-5'>
                    {category?.data?.length > 0 ? (
                        <Slide {...properties} cssClass='rounded-xl' slidesToScroll={5} slidesToShow={5} indicators={false} autoplay={false}>
                            {category?.data?.map((_, i) => (
                                <div className={`each-slide-effect px-1 mt-5`}>
                                    <a onClick={()=>navigate(`/danh-muc-blog/${_?.category_blog_slug}`)} className="cursor-pointer">
                                        <div className="">
                                            <div className="flex justify-center items-center">
                                                <img src={_?.category_blog_image} className={`${isMobile ? "w-[50px] h-[50px]" : "w-[100px] h-[100px]"} border rounded-full bg-gray-50`}/>
                                            </div>
                                            <div className="py-2">
                                                <h3 className={`${isMobile ? "text-[13px]" : "text-[16px]"} font-semibold text-center h-12 text-slate-800`}>{_?.category_blog_name}</h3>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </Slide>
                    ) : <Empty />}
                </div>
            ) : (
                <div className="flex items-center bg-white pt-5 px-10 mt-5">
                    <div className={`grid grid-cols-9 gap-4`}>
                        {category?.data?.map((_,i) => (
                            <a onClick={()=>navigate(`/danh-muc-blog/${_?.category_blog_slug}`)} className="cursor-pointer">
                                <div className="">
                                    <div className="flex justify-center items-center">
                                        <img src={_?.category_blog_image} className={`${isMobile ? "w-[50px] h-[50px]" : "w-[100px] h-[100px]"} border rounded-full bg-gray-50`}/>
                                    </div>
                                    <div className="py-2">
                                        <h3 className={`${isMobile ? "text-[13px]" : "text-[16px]"} font-semibold text-center h-12 text-slate-800`}>{_?.category_blog_name}</h3>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            )}
            <div className="bg-white mt-5 p-5">
                <h2 className="font-bold text-xl border-b mb-5">Bài viết mới nhất</h2>
                {isMobile ? (
                    <div>
                        {blog?.data?.length > 0 ? blog?.data?.map((_, i) => (
                            <a href={`/blog/${_?.blog_slug}`} className="cursor-pointer">
                                <div className="grid grid-cols-3 gap-4 border bg-white my-2">
                                    <div>
                                        <img 
                                            src={_?.blog_image} 
                                            className={`w-full h-[80px] bg-gray-50`} 
                                            alt={_?.blog_name} 
                                        />
                                    </div>
                                    <div className={`col-span-2`}>
                                        <p className={`text-[14px] font-semibold p-2 text-slate-800`}>
                                            {_?.blog_name}
                                        </p>
                                        <p className={`text-[12px] font-semibold px-2 text-slate-600`}>
                                            {dayjs(_?.create_at).format("DD/MM/YYYY")} / {_?.category_blog_name}
                                        </p>
                                    </div>  
                                </div>
                            </a>
                        )) : (
                            <div className={`col-span-6`}>
                                <Empty />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={`grid ${isMobile ? "grid-cols-1 gap-2" : "grid-cols-4 gap-4 mx-2"}`}>
                        {blog?.data?.length > 0 ? blog?.data?.map((_, i) => (
                            <a href={`/blog/${_?.blog_slug}`} className="cursor-pointer">
                                <div className="border bg-white">
                                    <div className="flex justify-center items-center">
                                        <img src={_?.blog_image} className={`${isMobile ? "h-[280px]" : "h-[280px]"} w-full bg-gray-50`}/>
                                    </div>
                                    <div className={`${isMobile ? "p-1" : "p-2"}`}>
                                        <p className={`text-[16px] truncate h-[30px] font-semibold px-2 text-slate-800`}>{_?.blog_name}</p>
                                        <p className="truncate h-[45px] px-2 text-gray-500">{parse(String(_?.blog_description)?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>"))}</p>
                                    </div>  
                                </div>
                            </a>
                        )) : (
                            <div className={`col-span-6`}>
                                <Empty />
                            </div>
                        )}
                    </div>
                )}
                
                <div className="mt-10">
                    <Pagination
                        className="flex justify-center"
                        current={pagination.page}
                        total={blog?.total}
                        pageSize={pagination.pageSize}
                        showSizeChanger
                        onChange={(p, ps)=> {
                            setPagination({
                                page: p,
                                pageSize: ps
                            })
                        }}
                    />
                </div>
            </div>
        </div>  
    )
}