import { useEffect, useState } from "react";
import { Col, Form, Menu, Row, message } from "antd";
import { BookOutlined, ShoppingCartOutlined, UserAddOutlined } from "@ant-design/icons";
import { useCookies } from "react-cookie";
import { useDevice } from "../../hooks";
import axiosInstance from "../../utils/axios";

export default function ChangePass() {
    const { isMobile } = useDevice();
    const [form] = Form.useForm();
    const [num, setNum] = useState(1);
    const [cookies] = useCookies(["user"]);
    

    const onSubmit = async (data) => {
        const dataNew = {
            ...data,
            email: cookies?.user?.email
        }
        if(data?.password == data?.passwordConfirm) {
            await axiosInstance.post('/user/changePassword', dataNew)
                .then(async (res) => {
                    if(res?.data?.status == 1){
                        message.success(String(res?.data?.message));
                    }else {
                        message.info(String(res?.data?.message));
                        form.resetFields();
                    }
                })
                .catch((error)=>{
                    message.error(error);
                })
        } else {
            message.info("Mật khẩu bạn nhập đang chưa khớp nhau!");
        }
    }

    const items = [
        {
          key: 'order',
          label: <a href="/thong-tin-ca-nhan">Đơn hàng của tôi</a>,
          icon: <ShoppingCartOutlined />
        },
        {
          key: 'my-book',
          label: <a href="/sach-cua-toi">Sách của tôi</a>,
          icon: <BookOutlined />
        },
        {
          key: 'affiliate',
          label: <a href="/tiep-thi-lien-ket">Tiếp thị liên kết</a>,
          icon: <BookOutlined />
        },
        {
          key: 'change-password',
          label: <a href="/doi-mat-khau">Đổi mật khẩu</a>,
          icon: <UserAddOutlined />
        }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 

    return (
        <div className={`max-w-screen-2xl mx-auto my-5 ${isMobile && "pt-14"}`}>
            <div className="flex pb-4">
                <a href="">Trang chủ</a> 
                <p className="px-2">/</p> 
                <a href="">Thông tin cá nhân</a>
            </div>

            <Row>
                <Col xs={24} xl={4} className="p-1">
                    <Menu
                        className="w-full"
                        onClick={(_)=>{
                            if(_?.key == "order") {
                                setNum(1);
                            } else if (_?.key == "my-book") {
                                setNum(2);
                            } else{
                                setNum(3);
                            }
                        }}
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={['sub1']}
                        mode="inline"
                        items={items}
                    />
                </Col>
                <Col xs={24} xl={20} className="p-1">
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Quên mật khẩu
                            </h1>
                                
                            <Form
                                layout={"vertical"}
                                colon={false}
                                form={form}
                                onFinishFailed={(e) => console.log(e)}
                                onFinish={onSubmit}
                            >
                                <Form.Item
                                    label={
                                        <label for="password" className="block text-sm font-medium text-gray-900 dark:text-white">
                                            Mật khẩu hiện tại
                                        </label>
                                    }
                                    name="passwordCurrent"
                                    rules={[{ required: true, message: "Vui lòng nhập Mật khẩu hiện tại!" }]}
                                >
                                    <input 
                                        type="password" 
                                        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                        placeholder="Mật khẩu hiện tại" 
                                        required="" />
                                </Form.Item>

                                <Form.Item
                                    label={
                                        <label for="password" className="block text-sm font-medium text-gray-900 dark:text-white">
                                            Mật khẩu mới
                                        </label>
                                    }
                                    name="password"
                                    rules={[{ required: true, message: "Vui lòng nhập Mật khẩu mới!" }]}
                                >
                                    <input 
                                        type="password" 
                                        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                        placeholder="Mật khẩu mới" 
                                        required="" />
                                </Form.Item>

                                <Form.Item
                                    label={
                                        <label for="password" className="block text-sm font-medium text-gray-900 dark:text-white">
                                            Xác nhận mật khẩu mới
                                        </label>
                                    }
                                    name="passwordConfirm"
                                    rules={[{ required: true, message: "Vui lòng nhập Mật khẩu mới!" }]}
                                >
                                    <input 
                                        type="password" 
                                        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                        placeholder="Xác nhận mật khẩu mới" 
                                        required="" />
                                </Form.Item>

                                <button 
                                    type="submit" 
                                    className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-lg px-5 py-2 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                >
                                    Xác nhận
                                </button>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}