import { Image, Layout, Menu, message } from "antd";
import React, { useEffect } from "react";
import {
  MessageOutlined,
  UserOutlined,
  VideoCameraOutlined,
  AppstoreOutlined,
  TransactionOutlined,
  LogoutOutlined,
  ContainerOutlined,
  NotificationOutlined,
  CreditCardOutlined,
  UnorderedListOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";
import logo from "../assets/icon/logo.png"
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
const { Sider } = Layout;


export default function CustomeSider() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['admin']);

  const menuItem = [
    {
      key: "user",
      icon: <UserOutlined />,
      label: <Link to={"/admin/quan-ly-nguoi-dung"}>Quản lý người dùng</Link>,
    }, 
    {
      key: "category",
      icon: <AppstoreOutlined />,
      label: "Quản lý danh mục",
      children: [
        {
          key: "categoryIndex",
          label: <Link to={"/admin/quan-ly-danh-muc-trang-chu"}>Danh mục trang chủ</Link>,
        },
        {
          key: "categoryParent",
          label: <Link to={"/admin/quan-ly-danh-muc"}>Danh mục cha</Link>,
        },
        {
          key: "categoryChild",
          label: <Link to={"/admin/quan-ly-danh-muc-con"}>Danh mục con</Link>,
        },
      ],
    },
    {
      key: "product",
      icon: <VideoCameraOutlined />,
      label: "Quản lý sản phẩm",
      children: [
        {
          key: "attributeManage",
          label: <Link to={"/admin/quan-ly-thuoc-tinh"}>Quản lý thuộc tính</Link>,
        },
        {
          key: "productManage",
          label: <Link to={"/admin/quan-ly-san-pham"}>Quản lý sản phẩm</Link>,
        },
      ],
    },
    {
      key: "indexManage",
      icon: <VideoCameraOutlined />,
      label: <Link to={"/admin/quan-ly-trang-chu"}>Quản lý trang chủ</Link>,
    },
    {
      key: "wareHouseManage",
      icon: <VideoCameraOutlined />,
      label: <Link to={"/admin/quan-ly-kho-hang"}>Quản lý kho hàng</Link>,
    },
    {
      key: "orderManage",
      icon: <VideoCameraOutlined />,
      label: <Link to={"/admin/quan-ly-don-hang"}>Quản lý đơn hàng</Link>,
    },
    {
      key: "categoryBlogManage",
      icon: <UnorderedListOutlined />,
      label: <Link to={"/admin/quan-ly-danh-muc-blog"}>Quản lý danh mục Blog</Link>,
    },
    {
      key: "blogManage",
      icon: <UnorderedListOutlined />,
      label: <Link to={"/admin/quan-ly-blog"}>Quản lý Blog</Link>,
    },
    {
      key: "emailMkt",
      icon: <UnorderedListOutlined />,
      label: <Link to={"/admin/quan-ly-email-mkt"}>Quản lý Email MKT</Link>,
    },
    {
      key: "voucherManage",
      icon: <CreditCardOutlined />,
      label: <Link to={"/admin/quan-ly-ma-giam-gia"}>Quản lý voucher</Link>,
    },
    {
      key: "commentManage",
      icon: <UnorderedListOutlined />,
      label: <Link to={"/admin/quan-ly-comment"}>Quản lý bình luận</Link>,
    },
    {
      key: "requestMoneyManage",
      icon: <MoneyCollectOutlined />,
      label: <Link to={"/admin/quan-ly-request-money"}>Quản lý rút tiền</Link>,
    },
    {
      key: "logout",
      icon: <LogoutOutlined />,
      label: <Link to={"/dang-nhap-admin"} onClick={() => {
        removeCookie("admin");
        localStorage.removeItem('token');
      }}>
        Thoát
      </Link>,
    },
  ];

  useEffect(()=> {
    if(!cookies?.admin) {
      removeCookie("admin");
      localStorage.removeItem('token');
      navigate("/dang-nhap-admin");
      message.warning("Bạn vui lòng đăng nhập bằng tài khoản của Admin!")
    }
  },[]);

  return (
    <div className="!bg-white">
      <Sider width={220} className="!w-full !bg-white">
        <div className="flex justify-center py-5">
          <Link to={"/admin"}>
            <Image
              preview={false}
              src={logo}
              width={50}
              height={50}
            />
          </Link>
        </div>
        <div className="flex items-center px-5 mb-2 border">
          <Image
            preview={false}
            src={cookies.admin?.photos}
            width={30}
            height={30}
          />
          <div className="ml-[10px] font-bold">
            {cookies.admin?.displayName}
          </div>
        </div>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={["1"]}
          items={menuItem}
        />
      </Sider>
    </div>
  );
}
