import React from 'react';
import { Route, Routes } from "react-router-dom";
import AdminPage from '../page/admin';
import UsersManage from '../page/admin/users';
import CategoryManage from '../page/admin/category';
import CategoryManageDetail from '../page/admin/category/detail';
import CategoryChildManage from '../page/admin/categoryChild';
import CategoryChildManageDetail from '../page/admin/categoryChild/detail';
import OrderManage from '../page/admin/order';
import ProductManage from '../page/admin/product';
import ProductManageDetail from '../page/admin/product/detail';
import AttributeManage from '../page/admin/attribute';
import AttributeManageDetail from '../page/admin/attribute/detail';
import WareHouseManage from '../page/admin/warehouse';
import WareHouseManageDetail from '../page/admin/warehouse/detail';
import CategoryIndexManage from '../page/admin/categoryIndex';
import CategoryIndexDetail from '../page/admin/categoryIndex/detail';
import IndexManage from '../page/admin/indexManage';
import IndexManageDetail from '../page/admin/indexManage/detail';
import VoucherManage from '../page/admin/voucher';
import VoucherManageDetail from '../page/admin/voucher/detail';
import BlogManage from '../page/admin/blog';
import BlogManageDetail from '../page/admin/blog/detail';
import EmailMkt from '../page/admin/emailMkt';
import CommentManage from '../page/admin/comment';
import RequestMoneyManage from '../page/admin/requestMoney';
import CategoryBlogManage from '../page/admin/categoryBlog';
import CategoryBlogManageDetail from '../page/admin/categoryBlog/detail';

export default function Admin () {
    return (
      <Routes>
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/admin/quan-ly-nguoi-dung" element={<UsersManage />} />
        <Route path="/admin/quan-ly-danh-muc-trang-chu" element={<CategoryIndexManage />} />
        <Route path="/admin/quan-ly-danh-muc-trang-chu/:id" element={<CategoryIndexDetail />} />
        <Route path="/admin/quan-ly-danh-muc" element={<CategoryManage />} />
        <Route path="/admin/quan-ly-danh-muc/:id" element={<CategoryManageDetail />} />
        <Route path="/admin/quan-ly-danh-muc-con" element={<CategoryChildManage />} />
        <Route path="/admin/quan-ly-danh-muc-con/:id" element={<CategoryChildManageDetail />} />
        <Route path="/admin/quan-ly-san-pham" element={<ProductManage />} />
        <Route path="/admin/quan-ly-san-pham/:id" element={<ProductManageDetail />} />
        <Route path="/admin/quan-ly-thuoc-tinh" element={<AttributeManage />} />
        <Route path="/admin/quan-ly-thuoc-tinh/:id" element={<AttributeManageDetail />} />
        <Route path="/admin/quan-ly-kho-hang" element={<WareHouseManage />} />
        <Route path="/admin/quan-ly-kho-hang/:id" element={<WareHouseManageDetail />} />
        <Route path="/admin/quan-ly-don-hang" element={<OrderManage />} />
        <Route path="/admin/quan-ly-trang-chu" element={<IndexManage />} />
        <Route path="/admin/quan-ly-trang-chu/:id" element={<IndexManageDetail />} />
        <Route path="/admin/quan-ly-ma-giam-gia" element={<VoucherManage />} />
        <Route path="/admin/quan-ly-ma-giam-gia/:id" element={<VoucherManageDetail />} />
        <Route path="/admin/quan-ly-danh-muc-blog" element={<CategoryBlogManage />} />
        <Route path="/admin/quan-ly-danh-muc-blog/:id" element={<CategoryBlogManageDetail />} />
        <Route path="/admin/quan-ly-blog" element={<BlogManage />} />
        <Route path="/admin/quan-ly-blog/:id" element={<BlogManageDetail />} />
        <Route path="/admin/quan-ly-email-mkt" element={<EmailMkt />} />
        <Route path="/admin/quan-ly-comment" element={<CommentManage />} />
        <Route path="/admin/quan-ly-request-money" element={<RequestMoneyManage />} />
      </Routes>
    );
};