import { useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import { Col, Menu, Row, message } from "antd";
import { BookOutlined, ShoppingCartOutlined, UserAddOutlined } from "@ant-design/icons";
import { getOrderByUser } from "../../api/order";
import { useCookies } from "react-cookie";
import TableOrder from "../../section/tableOrder";
import MyBook from "../../section/myBook";
import { getByUserId } from "../../api/myBook";
import { useDevice } from "../../hooks";

export default function Profile() {
    const { isMobile } = useDevice();
    const [num, setNum] = useState(1);
    const [order, setOrder] = useState([]);
    const [book, setBook] = useState([]);
    const [category_child_id, setCategoryChildId] = useState(0);
    const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 5,
    });
    const [paginationBook, setPaginationBook] = useState({
      page: 1,
      pageSize: 5,
    });
    const [cookies] = useCookies(["user"]);

    const items = [
        {
          key: 'order',
          label: <a href="/thong-tin-ca-nhan">Đơn hàng của tôi</a>,
          icon: <ShoppingCartOutlined />
        },
        {
          key: 'my-book',
          label: <a href="/sach-cua-toi">Sách của tôi</a>,
          icon: <BookOutlined />
        },
        {
          key: 'affiliate',
          label: <a href="/tiep-thi-lien-ket">Tiếp thị liên kết</a>,
          icon: <BookOutlined />
        },
        {
          key: 'change-password',
          label: <a href="/doi-mat-khau">Đổi mật khẩu</a>,
          icon: <UserAddOutlined />
        }
    ];

    useEffect(()=> {
        const query = `page=${pagination?.page}&pageSize=${pagination?.pageSize}`;
        getOrderByUser(cookies?.user?.user_id, query, setOrder);
        
        const queryBook = `page=${paginationBook?.page}&pageSize=${paginationBook?.pageSize}&category_child_id=${category_child_id}`;
        getByUserId(cookies?.user?.user_id, queryBook, setBook);
    }, [pagination, paginationBook, category_child_id])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 

    return (
        <div className={`max-w-screen-2xl mx-auto my-5 ${isMobile && "pt-14"}`}>
            <div className="flex pb-4">
                <a href="">Trang chủ</a> 
                <p className="px-2">/</p> 
                <a href="">Thông tin cá nhân</a>
            </div>

            <Row>
                <Col xs={24} xl={4} className="p-1">
                    <Menu
                        className="w-full"
                        onClick={(_)=>{
                            if(_?.key == "order") {
                                setNum(1);
                            } else if (_?.key == "my-book") {
                                setNum(2);
                            } else{
                                setNum(3);
                            }
                        }}
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={['sub1']}
                        mode="inline"
                        items={items}
                    />
                </Col>
                <Col xs={24} xl={20} className="p-1">
                    <TableOrder order={order} pagination={pagination} setPagination={setPagination} />
                </Col>
            </Row>
        </div>
    )
}