import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import ItemProduct from '../../section/itemProduct';
import { useEffect, useRef, useState } from 'react';
import axiosInstance from '../../utils/axios';
import { Col, Empty, Pagination, Row, message } from 'antd';
import { getAll, getAllIndexPage, getRandomIndexPage } from '../../api/category';
import { properties } from '../../utils/constants';
import ItemProductGrid from '../../section/itemProductGrid';
import CountdownTimer from '../../component/countdownTimer';
import { getAllBlog } from "../../api/blog";
import ItemBlog from '../../section/itemBlog';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

export default function HomeIsMobile() {
    const navigate = useNavigate();
    const [category, setCategory] = useState([]);
    const [categoryIndex, setCategoryIndex] = useState([]);
    const [index, setIndex] = useState([]);
    const [randomIndex, setRandomIndex] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(10);
    const [blog, setBlog] = useState();
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 12,
    });
    
    const fetchIndexPage = async () => {
        await axiosInstance.get(`/index-page/getAllIndexPage`)
        .then((res) => {
            const data = res?.data;
            setIndex(data);
        })
        .catch(() => message.error("Error server!"));
    };

    const handleLoadMore = () => {
        setItemsToShow(prev => prev + 10);
    };

    useEffect(()=> {        
        const query = `page=${pagination?.page}&pageSize=${pagination?.pageSize}`;
        getAllBlog(query, setBlog);
    }, [pagination])
    
    useEffect(() => {
        let query = `page=1&pageSize=${itemsToShow}`;
        getRandomIndexPage(query, setRandomIndex);
        getAll("page=1&pageSize=100", setCategory);
        getAllIndexPage(setCategoryIndex);
        getRandomIndexPage(setRandomIndex);
        fetchIndexPage();
    }, [itemsToShow]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 

    return (
        <div className="max-w-screen-2xl mx-auto pt-12">
            <Col xs={24} xl={20}>
                <div className='my-2 py-4 bg-white rounded-lg'>
                    {index?.data?.length > 0 ? (
                        <Slide cssClass='rounded-xl' {...properties}>
                            {index?.data?.map((_,i) => (
                                <div className="each-slide-effect flex justify-center h-[150px]">
                                    <a href={_?.index_page_link}>
                                        <img 
                                            src={_?.index_page_image}
                                            className='w-full h-full'
                                        />
                                    </a>
                                </div>
                            ))}
                        </Slide>
                    ): (
                        <Empty />
                    )}
                </div>

                <div className='p-5 bg-white rounded-xl'>
                    <div className='grid grid-cols-5 gap-4'>
                        {category?.data?.map((_,i)=> (
                            <a href={`/danh-muc-san-pham/${_?.category_slug}`}>
                                <div className='flex justify-center'>
                                    <img src={_?.category_image} className='rounded-xl h-[40px] w-[40px]'/>
                                </div>
                                <p className='text-center pt-1 text-[10px] font-semibold text-black'>{_?.category_name}</p>
                            </a>
                        ))}
                    </div>
                </div>
                
                {categoryIndex?.data?.map((_,i) => (
                    <div className='my-2 p-5 bg-white rounded-xl'>
                        <div className='flex justify-between'>
                            <div className='flex items-center'>
                                <img src={_?.category_index_image} className='h-8'/>
                                {_?.category_index_id == 2 && (
                                    <CountdownTimer />
                                )}
                            </div>
                            <a href={`/danh-muc-trang-chu/${_?.category_index_id}`}>
                                <p className='font-semibold text-[14px] text-[#003EA1]'>Xem tất cả</p>
                            </a>
                        </div>
                        <div className='py-8'>
                            <ItemProduct product={_?.product} grid={3} titleSize={13} heightTitle={10}/>
                        </div>
                    </div>
                ))}

                <div className='my-2 p-5 bg-white rounded-xl'>
                    <div className='flex justify-between'>
                        <p className='font-bold text-xl'>Gợi ý hôm nay</p>
                        <a href='/danh-muc-trang-chu/0'>
                            <p className='font-semibold text-[14px] text-[#003EA1]'>Xem tất cả</p>
                        </a>
                    </div>
                    <div className='py-8'>
                        <ItemProductGrid product={randomIndex?.data} grid={2} titleSize={13} heightTitle={10}/>
                    </div>
                    <div className='flex justify-center'>
                        {itemsToShow < randomIndex?.total && (
                            <button className='border px-4 py-2 font-semibold text-lg text-blue-600 border-blue-600 hover:bg-blue-100' onClick={handleLoadMore}>Xem thêm</button>
                        )}
                    </div>
                </div>
                <div className="bg-white my-2 p-5 rounded-lg">
                    <h2 className="font-bold text-xl border-b">Bài viết mới</h2>
                    <div className="py-5">
                        {/* <ItemBlog blog={blog?.data} /> */}
                        <div>
                            {blog?.data?.length > 0 ? blog?.data?.map((_, i) => (
                                <a href={`/blog/${_?.blog_slug}`} className="cursor-pointer">
                                    <div className="grid grid-cols-3 gap-4 border bg-white my-2">
                                        <div>
                                            <img 
                                                src={_?.blog_image} 
                                                className={`w-full h-[80px] bg-gray-50`} 
                                                alt={_?.blog_name} 
                                            />
                                        </div>
                                        <div className={`col-span-2`}>
                                            <p className={`text-[14px] font-semibold p-2 text-slate-800`}>
                                                {_?.blog_name}
                                            </p>
                                            <p className={`text-[12px] font-semibold px-2 text-slate-600`}>
                                                {dayjs(_?.create_at).format("DD/MM/YYYY")} / {_?.category_blog_name}
                                            </p>
                                        </div>  
                                    </div>
                                </a>
                            )) : (
                                <div className={`col-span-6`}>
                                    <Empty />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='flex justify-center'>
                        <button className='border px-4 py-2 font-semibold text-lg text-blue-600 border-blue-600 hover:bg-blue-100' onClick={()=> navigate("/blog")}>Xem thêm</button>
                    </div>
                </div>
            </Col>
        </div>
    )
}