import { Form, message } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CategoryBlogForm from "../../../component/categoryBlogForm";
import { useCookies } from "react-cookie";
import axiosInstance from "../../../utils/axios";

export default function CategoryBlogManageDetail() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});
  const params = useParams();
  const id = params?.id;
  const [cookies, setCookie, removeCookie] = useCookies(['admin']);

  const fetchCategory = async () => {
    await axiosInstance.get(`/category-blog/getById/${params?.id}`)
      .then((res) => {
        const data = res?.data?.data[0];
        const values = {
          ...data,
        };
        setInitialValues(values);
      });
  };

  const onSubmit = async (values) => {
    try {
      if (id && id !== "tao-moi") {
        await axiosInstance.post(`/category-blog/update/${id}`, values)
          .then((res) => {
            message.success(String(res?.data?.message));
          });
      } else {
        await axiosInstance.post(`/category-blog/create`, values)
          .then((res) => {
            message.success(String(res?.data?.message));
          });
      }
      navigate("/admin/quan-ly-danh-muc-blog");
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    if (id && id !== "tao-moi") fetchCategory();
    form.resetFields();
  }, [form, id]);

  return (
    <CategoryBlogForm
      id={id !== "create" ? id : undefined}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
}
