import { Table, Row, Col, message, Modal, Space, Select, Pagination } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined, InfoOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import SearchProps from "../../../component/SearchProps";
import dayjs from "dayjs";
import axiosInstance from "../../../utils/axios";

export default function UsersManage() {
  const [user, setUser] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });

  const fetchUser = async () => {
    await axiosInstance.get(`/user/getAll`, {params: pagination})
      .then((res) => {
        const data = res?.data;
        setUser(data || []);
      })
      .catch(() => message.error("Error server!"));
  };

  const updateUser = async (value, id) => {
    await axiosInstance.post(`/user/updateRole/${id}`, {role: value})
      .then((res) => {
        fetchUser();
        message.success(String(res?.data?.message));
      });
  }

  const removeUser = async (user_id) => {
    await axiosInstance.delete(`/user/delete/${user_id}`)
      .then((res) => {
        fetchUser();
        message.success(String(res?.data?.message));
      });
  };

  const confirmDelete = (user_id) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: "Bạn có chắc chắn xoá người dùng này?",
      okText: "Xác nhận",
      cancelText: "Huỷ",
      onOk: () => removeUser(user_id),
    });
  };

  useEffect(() => {
    fetchUser();
  }, [pagination]);


  const optionRole = [
    { value: 1, label: "User" },
    { value: 2, label: "Admin" },
  ];
  const columns = [
    {
      title: <div className={"base-table-cell-label"}>ID</div>,
      key: "user_id",
      dataIndex: "user_id",
      sorter: (a, b) => a.user_id - b.user_id,
      width: 50,
      render: (_, record) => <div>{record?.user_id}</div>,
    },
    {
      title: <div className={"base-table-cell-label"}>Họ và Tên</div>,
      key: "displayName",
      dataIndex: "displayName",
      width: 280,
      ...SearchProps("displayName"),
      render: (_, record) => <div>{record?.displayName}</div>,
    },
    {
      title: <div className={"base-table-cell-label"}>Email</div>,
      key: "email",
      dataIndex: "email",
      width: 280,
      ...SearchProps("email"),
      render: (_, record) => {
        return <div>{record?.email}</div>;
      },
    },
    {
      title: <div className={"base-table-cell-label"}>Số điện thoại</div>,
      key: "phone",
      dataIndex: "phone",
      width: 280,
      ...SearchProps("phone"),
      render: (_, record) => {
        return <div>{record?.phone}</div>;
      },
    },
    {
      title: <div className={"base-table-cell-label"}>Giới tính</div>,
      key: "gender",
      dataIndex: "gender",
      width: 280,
      render: (_, record) => {
        return <div>{record?.gender}</div>;
      },
    },
    {
      title: <div className={"base-table-cell-label"}>Vai trò</div>,
      key: "role",
      dataIndex: "role",
      width: 280,
      filters: [
        { text: 'User', value: 1 },
        { text: 'Admin', value: 2 },
      ],
      onFilter: (value, record) => {
        return record?.role === value;
      },
      render: (_, record) => {
        return (
          <div>
            <Select
              options={optionRole}
              className={"w-[100px]"}
              value={record?.role === 1 ? "User" : "Admin"}
              defaultValue={record?.role === 1 ? "User" : "Admin"}
              onChange={(value) => updateUser(value, record?.user_id)}
            />
          </div>
        );
      },
    },
    {
      title: <div className={"base-table-cell-label "}>Ngày tạo</div>,
      key: "create_at",
      dataIndex: "create_at",
      width: 280,
      sorter: (a, b) => dayjs(a.create_at) - dayjs(b.create_at),
      render: (_, record) => {
        return (
          <div>
            <span className={"!inline-block min-w-[100px]"}>
              {dayjs(record?.create_at).format("DD/MM/YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      key: "operation",
      dataIndex: "operation",
      width: 100,
      render: (_, record) => {
        return (
          <Space>
            <div>
              <InfoOutlined className="text-white text-xl rounded-full bg-sky-400 p-1 cursor-pointer" />
            </div>
            <div
              onClick={() => confirmDelete(record?.user_id)}
            >
              <DeleteOutlined className="text-white text-xl rounded-full bg-red-500 p-1 ml-1 cursor-pointer" />
            </div>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={10} className={"mb-[8px]"}>
        <Col flex={1}>
          <div className={"text-[20px] font-medium"}>Quản lý người dùng</div>
        </Col>
        {/* <Col>
          <Link to={"/admin/users/create"}>
            <Button type={"primary"} onClick={() => {}}>
              Tạo
            </Button>
          </Link>
        </Col> */}
      </Row>

      <div className="w-full h-full mt-5 pb-20 relative">
        <Table
          className={"custom-table"}
          dataSource={user?.data}
          columns={columns}
          pagination={false}
        />
        <Pagination
          className="flex justify-center absolute inset-x-0 bottom-10"
          current={pagination.page}
          total={user?.total?.[0]?.total}
          pageSize={pagination.pageSize}
          showSizeChanger
          onChange={(p, ps)=> {
            setPagination({
              page: p,
              pageSize: ps
            })
          }}
        />
      </div>
    </>
  );
}
