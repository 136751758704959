import axiosInstance from "../utils/axios";

export const getAllBlog = async (query, setData) => {
    try {
        await axiosInstance.get(`/blog/getAll?${query}`)
            .then((res) => {
                setData(res?.data);
            })
    } catch (error) {
        console.log(error);
    }
}

export const getAll = async (setData) => {
    try {
        await axiosInstance.get(`/category-blog/getAllBlog`)
            .then((res) => {
                setData(res?.data);
            })
    } catch (error) {
        console.log(error);
    }
}

export const getBySlug = async (slug, setData) => {
    try {
        await axiosInstance.get(`/blog/getBySlug/${slug}`)
            .then((res) => {
                setData(res?.data);
            })
    } catch (error) {
        console.log(error);
    }
}

export const getByCategorySlug = async (slug, pagination, setData) => {
    try {
        await axiosInstance.get(`/category-blog/getBySlug/${slug}`, {params: pagination})
            .then((res) => {
                setData(res?.data);
            })
    } catch (error) {
        console.log(error);
    }
}