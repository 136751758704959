import { Col, Row } from 'antd';
import * as React from 'react';

export default function Footer() {
    return (
        <footer className='bg-white py-20'>
            <Row className='max-w-screen-2xl mx-auto px-5'>
                <Col xs={12} xl={6}>
                    <div className='flex justify-center'>
                        <div className='leading-7 w-[220px] text-[13px]'>
                            <h4 className='text-lg font-semibold pb-2'>Hỗ trợ khách hàng</h4>
                            <a href=''>
                                <p>
                                    Mail: hotro@cuahangykhoa.com
                                </p>
                            </a>
                            <a href=''>
                                <p>
                                    Điện thoại: 0847 799 799
                                </p>
                            </a>
                            <a href=''>
                                <p>
                                    Zalo: 0847 799 799
                                </p>
                            </a>
                            <a href='https://www.facebook.com/cuahangykhoacom'>
                                <p>
                                    Facebook: Cửa Hàng Y Khoa
                                </p>
                            </a>
                        </div>
                    </div>
                </Col>
                <Col xs={12} xl={6}>
                    <div className='flex justify-center'>
                        <div className='leading-7 w-[220px] text-[13px]'>
                            <h4 className='text-lg font-semibold pb-2'>Liên hệ hợp tác</h4>
                            <a href=''>
                                <p>
                                    Điện thoại: 0847 799 799
                                </p>
                            </a>
                            <a href=''>
                                <p>
                                    Mail: hotro@cuahangykhoa.com
                                </p>
                            </a>
                            <a href=''>
                                <p>
                                    Zalo: 0847 799 799
                                </p>
                            </a>
                            <a href='https://www.facebook.com/cuahangykhoacom'>
                                <p>
                                    Facebook: Cửa Hàng Y Khoa
                                </p>
                            </a>
                        </div>
                    </div>
                </Col>
                <Col xs={12} xl={6}>
                    <div className='flex justify-center'>
                        <div className='leading-7 w-[220px] text-[13px]'>
                            <h4 className='text-lg font-semibold pb-2'>Thông tin cửa hàng</h4>
                            <a href='https://docsachxyz.com/gioithieucuahang'>
                                <p>
                                    Giới thiệu cửa hàng
                                </p>
                            </a>
                            <a href='https://docsachxyz.com/giayphepkinhdoanh'>
                                <p>
                                    Giấy phép kinh doanh
                                </p>
                            </a>
                            <a href='https://docsachxyz.com/doitachoptac'>
                                <p>
                                    Đối tác hợp tác
                                </p>
                            </a>
                            <a href='https://docsachxyz.com/cauhoi5'>
                                <p>
                                    Các câu hỏi thường gặp
                                </p>
                            </a>
                        </div>
                    </div>
                </Col>
                <Col xs={12} xl={6}>
                    <div className='flex justify-center'>
                        <div className='leading-7 w-[220px] text-[13px]'>
                            <h4 className='text-lg font-semibold pb-2'>Câu hỏi thường gặp</h4>
                            <a href=''>
                                <p>
                                    Điện thoại: 0847 799 799
                                </p>
                            </a>
                            <a href='https://docsachxyz.com/cauhoi1'>
                                <p>
                                    Các câu hỏi thường gặp
                                </p>
                            </a>
                            <a href='https://docsachxyz.com/cauhoi2'>
                                <p>
                                    Các câu hỏi thường gặp
                                </p>
                            </a>
                            <a href='https://docsachxyz.com/cauhoi3'>
                                <p>
                                    Các câu hỏi thường gặp
                                </p>
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
        </footer>
    )
}